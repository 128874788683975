import axios, { AxiosResponse } from "axios";
import { Candidate } from "../../models";

const apiClient = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

export const searchCandidates = async (searchString: string) => {
  const trimmedSearchInput = searchString.trim();
  try {
    const achrmresponse = await apiClient.get(`/candidate`, {
      baseURL: baseUrl(),
      params: { query: trimmedSearchInput },
    });

    const mhrsdresponse = await apiClient.get(`/mhrsd/candidates`, {
      baseURL: baseUrl(),
      params: { query: trimmedSearchInput },
    });

    return {
      data: [
        ...achrmresponse.data.candidates,
        ...mhrsdresponse.data.candidates,
      ],
      error: null,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { data: null, error };
  }
};

export const searchOldCertificates = async (
  studentId: string,
  certificateNo: string
) => {
  try {
    const achrmresponse = await apiClient.get(`/candidate`, {
      baseURL: baseUrl(),
      params: { query: studentId },
    });
    const filteredCertificate = achrmresponse.data.candidates.find(
      (cand: Candidate) => cand.certNo === certificateNo
    );
    return {
      data: [...filteredCertificate.data.candidates],
      error: null,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { data: null, error };
  }
};

export const retrieveCertificate = async (
  candidateId: string,
  certId: string
) => {
  try {
    const fetchFirst = fetch(
      baseUrl() + `/candidates/${candidateId}/certificates/${certId}`
    );
    const fetchSecond = fetch(
      baseUrl() + `/mhrsd/candidates/${candidateId}/certificates/${certId}`
    );

    const results = await Promise.allSettled([fetchFirst, fetchSecond]);
    // Check which promise succeeded
    const successfulResponse = results.find(
      (result) =>
        result.status === "fulfilled" &&
        (result as PromiseFulfilledResult<Response>).value.status === 200
    );
    if (successfulResponse) {
      return {
        data: await (
          successfulResponse as PromiseFulfilledResult<Response>
        ).value.json(),
        error: null,
      };
    } else {
      return { data: null, error: new Error("Network response was not ok") };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { data: null, error };
  }
};

export const baseUrl = () => {
  return (window as any).config?.REACT_APP_API_BASE_URL;
};
